import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resi1',
  templateUrl: '../resibase/resibase.html',
  styleUrls: ['../resibase/resibase.css']
})
export class Resi1Component implements OnInit {

  constructor() { }
  bloc1 = {
    image: '/assets/resi1.jpg',
    texte: 'Résidence Château de la Brise',
    ville: 'Paris (75015)',
    description: 'Nichée au cœur dun paysage champêtre, cette résidence rustique exhale le charme authentique de la vie à la campagne. Entourée de prairies verdoyantes et de collines ondulantes, la maison en pierre, aux volets en bois patiné, semble avoir été figée dans le temps. Un sentier pavé serpente à travers un jardin luxuriant, orné de fleurs sauvages colorées et darbustes touffus. À lintérieur, une chaleur réconfortante émane des murs en pierre apparente et des poutres en bois massif. Le salon accueillant est agrémenté dune cheminée en pierre, créant un point focal chaleureux pour les soirées dhiver. Les sols en terre cuite et les meubles en bois antique ajoutent une touche dauthenticité, rappelant le caractère intemporel de la propriété.',
    description2: 'La cuisine spacieuse, avec ses comptoirs en granit et ses appareils en acier inoxydable, offre un mélange harmonieux de tradition et de modernité. Les chambres, baignées de lumière naturelle, offrent des vues panoramiques sur les vastes étendues de la campagne environnante. À lextérieur, une terrasse en bois surplombe un petit étang, créant un endroit idéal pour savourer la tranquillité de cet environnement rural. Cette résidence rustique offre un refuge paisible dans un cadre bucolique, où le temps semble sécouler à un rythme plus doux.'
  };
  imageUrls: string[] = [
    '/assets/resi2.jpeg',
    '/assets/resi1.jpg',
    '/assets/resi3.jpg',
  ];
  currentIndex = 0;

  showImage(index: number) {
    this.currentIndex = index;
  }

  navigateLeft() {
    this.currentIndex = (this.currentIndex - 1 + this.imageUrls.length) % this.imageUrls.length;
  }

  navigateRight() {
    this.currentIndex = (this.currentIndex + 1) % this.imageUrls.length;
  }
  ngOnInit(): void {
  }

}
