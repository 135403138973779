<div class="blue-div">
  <p class="titre2">Nos résidences disponible dès aujourd'hui</p>
</div>
<div class ="container">
  <div class="image-container">
  <img [src]="bloc1.image" alt="Image 1">
  <p class ="titre">{{ bloc1.texte }}</p>
  <p class ="descr">{{ bloc1.description}}</p>
  <button class = "button" routerLink="/residences/1">Voir la résidence </button>
</div>

  <div class="image-container">
  <img [src]="bloc6.image" alt="Image 6">
  <p class ="titre">{{ bloc2.texte }}</p>
  <p class ="descr">{{ bloc2.description}}</p>
  <button class = "button" routerLink="/residences/1">Voir la résidence </button>
</div>
</div>
  <div class ="container">
      <div class="image-container">
      <img [src]="bloc3.image" alt="Image 3">
      <p class ="titre">{{ bloc3.texte }}</p>
      <p class ="descr">{{ bloc3.description}}</p>
      <button class = "button" routerLink="/residences/1">Voir la résidence </button>
    </div>
    
      <div class="image-container">
      <img [src]="bloc4.image" alt="Image 4">
      <p class ="titre">{{ bloc4.texte }}</p>
      <p class ="descr">{{ bloc4.description}}</p>
      <button class = "button" routerLink="/residences/1">Voir la résidence </button>
    </div>
  </div>
  <div class ="container">
      <div class="image-container">
      <img [src]="bloc5.image" alt="Image 5">
      <p class ="titre">{{ bloc5.texte }}</p>
      <p class ="descr">{{ bloc5.description}}</p>
      <button class = "button" routerLink="/residences/1">Voir la résidence </button>
    </div>
    
      <div class="image-container">
      <img [src]="bloc6.image" alt="Image 6">
      <p class ="titre">{{ bloc6.texte }}</p>
      <p class ="descr">{{ bloc6.description}}</p>
      <button class = "button" routerLink="/residences/1">Voir la résidence </button>
    </div>
  </div>