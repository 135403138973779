import { Component, OnInit, AfterViewInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as L from 'leaflet';
import "leaflet/dist/images/marker-shadow.png";
import { HttpClient } from '@angular/common/http';
import "leaflet/dist/images/marker-icon.png";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
  trigger('fade', [
    state('void', style({ opacity: 0 })),
    state('visible', style({ opacity: 1 })),
    transition('void => visible', animate(500)),
    transition('visible => void', animate(500)),
  ]),
    trigger('slide', [
      state('void', style({ transform: 'translateX(30%)', opacity: 0 })),
      state('visible', style({ transform: 'translateX(0)', opacity: 1 })),
      transition('void => visible', animate('400ms ease-in-out')),
      transition('visible => void', animate('100ms ease-in-out')),
    ]),
  ],
})
export class HomeComponent implements OnInit, AfterViewInit {
  mapVisible=false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initMap();
    setTimeout(() => this.mapVisible = true, 500);
  }
  bloc1 = {
    image: '/assets/resi1.jpg',
    texte: 'Résidence Château de la Brise',
  };
  
  bloc2 = {
    image: '/assets/resi2.jpeg',
    texte: 'Résidence Belle étoile',
  };
  bloc3 = {
    image: '/assets/resi3.jpg',
    texte: 'Résidence des Cypres bleus',
  };
  
  bloc4 = {
    image: '/assets/resi3.jpg',
    texte: 'Résidence des lumières',
  };
  bloc5 = {
    image: '/assets/resi1.jpg',
    texte: 'Résidence du jardin secret',
  };
  
  bloc6 = {
    image: '/assets/resi2.jpeg',
    texte: 'Résidence de la Sérénité',
  };
  private initMap(): void {
    const map = L.map('map', {
      center: [48.8491085, 2.8227676],
      zoom: 13,
      scrollWheelZoom: false
    });
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(map);

    setTimeout(() => map.invalidateSize(), 500);

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          map.setView([latitude, longitude], 13);
        },
        (error) => {
          console.error('Error getting user location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser');
    }

    const mapContainer = document.getElementById('map');
    if (mapContainer) {
      mapContainer.style.width = '95%';
      mapContainer.style.height = '50vw';
    }
    const markers = [
      { "lat": 48.849, "lon": 2.822, "title": "Réisdence Chateau de la Brise", "redirectUrl": "/residences/1" , "img": "assets/resi1.jpg"},
      { "lat": 48.850, "lon": 2.820, "title": "Résidence Belle étoile", "redirectUrl": "/residences/2" , "img": "assets/resi2.jpeg"},
      { "lat": 48.851, "lon": 2.825, "title": "Résidence des Cypres bleus", "redirectUrl": "/residences/3" , "img": "assets/resi3.jpg"},
      { "lat": 48.852, "lon": 2.830, "title": "Résidence des Lumières", "redirectUrl": "/residences/4" , "img": "assets/resi1.jpg"},
      { "lat": 48.853, "lon": 2.835, "title": "Réisdence du Jardin Secret", "redirectUrl": "/residences/5" , "img": "assets/resi2.jpeg"},
      { "lat": 48.855, "lon": 2.840, "title": "Résidence de la Sérénité", "redirectUrl": "/residences/6" , "img": "assets/resi3.jpg"},
    ];
    markers.forEach((marker) => {
      const newMarker = L.marker([marker.lat, marker.lon]).addTo(map);
      
      // Construct the popup content with a link
      const popupContent = `<b>${marker.title}</b><br/><a href="${marker.redirectUrl}">Aller sur la fiche de la résidence </a><br/><img src=${marker.img} alt="Large Image" width="200" height="200"/>`;
      
      newMarker.bindPopup(popupContent);
      
      // Open popup on click (optional)
      newMarker.on('click', () => newMarker.openPopup());
    });
  }
}
