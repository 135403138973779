
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDqiUdurS7Bv3uc7BSqs7YMI8RtTB0vAd8",
    authDomain: "royalpromotion-c9740.firebaseapp.com",
    projectId: "royalpromotion-c9740",
    storageBucket: "royalpromotion-c9740.appspot.com",
    messagingSenderId: "872142708516",
    appId: "1:872142708516:web:ab407bc1014751834f9db1"
  }
};
