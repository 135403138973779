<header class="app-header">
  <div class="logo">
    <img src="assets/logo.png" alt="My Angular App">
  </div>
  <nav class="nav-menu">
    <ul>
      <li><a routerLink="/home" routerLinkActive="active">Accueil</a></li>
      <li (mouseenter)="showSubButtons = true" (mouseleave)="showSubButtons = false">
        <a routerLink="/residences" routerLinkActive="active">Nos résidences</a>
        <ul *ngIf="showSubButtons">
          <li><a routerLink="/residences/1">Résidence Château de la Brise</a></li>
          <li><a routerLink="/residences/2">Résidence Belle étoile</a></li>
          <li><a routerLink="/residences/3">Résidence des Cypres bleus</a></li>
          <li><a routerLink="/residences/4">Résidence des lumières</a></li>
          <li><a routerLink="/residences/5">Résidence du jardin secret</a></li>
          <li><a routerLink="/residences/6">Résidence de la Sérénité</a></li>
        </ul>
      </li>
      <li><a routerLink="/realisations" routerLinkActive="active">Nos réalisations</a></li>
      <li><a routerLink="/whoarewe" routerLinkActive="active">Qui sommes nous ?</a></li>
    </ul>
  </nav>

  <div class="logo">
    <img src="assets/logo.png" alt="My Angular App">
  </div>
</header>
<router-outlet></router-outlet>
<footer>
  <div class="blue-div">
    <div class="text-container">
    <p>2023 Rémi SALEH, All Rights Reserved.</p>
    </div>
  </div>
</footer>