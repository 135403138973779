import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ResidencesComponent } from './residences/residences.component';
import { WhoweareComponent } from './whoweare/whoweare.component';
import { RealisationsComponent } from './realisations/realisations.component';
import { Resi1Component } from './residences/resi1/resi1.component';
import { Resi2Component } from './residences/resi2/resi2.component';
import { Resi3Component } from './residences/resi3/resi3.component';
import { Resi4Component } from './residences/resi4/resi4.component';
import { Resi5Component } from './residences/resi5/resi5.component';
import { Resi6Component } from './residences/resi6/resi6.component';



const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'realisations', component: RealisationsComponent },
  { path: 'residences', component: ResidencesComponent },
  { path: 'whoarewe', component: WhoweareComponent},
  { path: 'residences/1', component: Resi1Component },
  { path: 'residences/2', component: Resi2Component },
  { path: 'residences/3', component: Resi3Component },
  { path: 'residences/4', component: Resi4Component },
  { path: 'residences/5', component: Resi5Component },
  { path: 'residences/6', component: Resi6Component },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}