import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-residences',
  templateUrl: './residences.component.html',
  styleUrls: ['./residences.component.css']
})
export class ResidencesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  bloc1 = {
    image: '/assets/resi1.jpg',
    texte: 'Résidence Château de la Brise',
    description: 'Cette résidence ce situe 4 rue de Paris. Elle propose 18 appartements de 2 à 4 chambres, un beau jardin zoirnoezi roein oreoin roi oi noi n on nio innoi noi oin nion iooi noi'    
  };
  
  bloc2 = {
    image: '/assets/resi2.jpeg',
    texte: 'Résidence Belle étoile',
    description: 'Cette résidence ce situe 4 rue de Paris. Elle propose 18 appartements de 2 à 4 chambres, un beau jardin zoirnoezi roein oreoin roi oi noi n on nio innoi noi oin nion iooi noi'
  };
  bloc3 = {
    image: '/assets/resi3.jpg',
    texte: 'Résidence des Cypres bleus',
    description: 'Cette résidence ce situe 4 rue de Paris. Elle propose 18 appartements de 2 à 4 chambres, un beau jardin zoirnoezi roein oreoin roi oi noi n on nio innoi noi oin nion iooi noi'
  };
  
  bloc4 = {
    image: '/assets/resi3.jpg',
    texte: 'Résidence des lumières',
    description: 'Cette résidence ce situe 4 rue de Paris. Elle propose 18 appartements de 2 à 4 chambres, un beau jardin zoirnoezi roein oreoin roi oi noi n on nio innoi noi oin nion iooi noi'
  };
  bloc5 = {
    image: '/assets/resi1.jpg',
    texte: 'Résidence du jardin secret',
    description: 'Cette résidence ce situe 4 rue de Paris. Elle propose 18 appartements de 2 à 4 chambres, un beau jardin zoirnoezi roein oreoin roi oi noi n on nio innoi noi oin nion iooi noi'
  };
  
  bloc6 = {
    image: '/assets/resi2.jpeg',
    texte: 'Résidence de la Sérénité',
    description: 'Cette résidence ce situe 4 rue de Paris. Elle propose 18 appartements de 2 à 4 chambres, un beau jardin zoirnoezi roein oreoin roi oi noi n on nio innoi noi oin nion iooi noi'
  };

}
