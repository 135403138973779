<div class="main-container">
  <div [@fade]="mapVisible ? 'visible' : 'hidden'" class="blue-div">
    <div class="text-container">
      <p class="titre">Trouvez dès aujourd'hui votre bien immobilier</p>
      <p class="descr">Utilisez la carte ci-dessous pour découvrir les biens immobilier développés par ROYAL PROMOTION</p>
    </div>
    <img src="assets/home_page.png" class="right-image" alt="Home Page" [@slide]="mapVisible ? 'visible' : 'void'">
  </div>
  <p class="titr2">Trouvez sur la carte le bien qui vous correspond !</p>
  <div id="map" [@fade]="mapVisible ? 'visible' : 'hidden'"></div>
</div>
<p class="titr3">Nos Résidences</p>
<div class="container">
  <div class="image-container">
    <img [src]="bloc1.image" alt="Image 1">
    <p>{{ bloc1.texte }}</p>
    <button class = "button" routerLink="/residences/1">Voir la résidence </button>
  </div>
  <div class="image-container">
    <img [src]="bloc2.image" alt="Image 2">
    <p>{{ bloc2.texte }}</p>
    <button class = "button" routerLink="/residences/2">Voir la résidence </button>
  </div>
  <div class="image-container">
    <img [src]="bloc3.image" alt="Image 3">
    <p>{{ bloc3.texte }}</p>
    <button class = "button" routerLink="/residences/3">Voir la résidence </button>
  </div>
</div>
<div class ="container">
  <div class="image-container">
    <img [src]="bloc4.image" alt="Image 4">
    <p>{{ bloc4.texte }}</p>
    <button class = "button" routerLink="/residences/4">Voir la résidence </button>
  </div>
  <div class="image-container">
    <img [src]="bloc5.image" alt="Image 5">
    <p>{{ bloc5.texte }}</p>
    <button class = "button" routerLink="/residences/5">Voir la résidence </button>
  </div>
  <div class="image-container">
    <img [src]="bloc6.image" alt="Image 6">
    <p>{{ bloc6.texte }}</p>
    <button class = "button" routerLink="/residences/6">Voir la résidence </button>
  </div>
</div>
<div class = "classdernierboutton">
  <button class = "buttonAlleralautrepage" routerLink="/residences/">Voir toutes les réisdences</button>
</div>
