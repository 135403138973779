<div class="big-image">
    <img [src]="bloc1.image" alt="Image 1">
        <div class = text-container>
            <p class="titre-on-image">{{bloc1.texte}}</p>
            <p class="ville">{{bloc1.ville}}</p>
        </div>
</div>
<div>
    <p class= description1> {{bloc1.description}}</p>
</div>

<div class="image-container">
    <div class="navigation-button" (click)="navigateLeft()">&lt;</div>
    <img [src]="imageUrls[currentIndex]" alt="Image" class="small-image">
    <div class="navigation-button" (click)="navigateRight()">&gt;</div>
</div>


<div>
    <p class= description2> {{bloc1.description2}}</p>
</div>