import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resi3',
  templateUrl: '../resibase/resibase.html',
  styleUrls: ['../resibase/resibase.css']
})
export class Resi3Component implements OnInit {

  constructor() { }
  bloc1 = {
    image: '/assets/resi3.jpg',
    ville: 'Paris (75015)',
    texte: 'Résidence des Cypres bleus',
    description: 'Au cœur du tissu urbain animé, cette résidence contemporaine incarne lélégance moderne et le confort luxueux. Ses lignes épurées et sa façade en verre captivent le regard, reflétant la lumière changeante du soleil tout au long de la journée. Les espaces de vie intérieurs sont baignés de lumière naturelle, grâce aux fenêtres panoramiques qui encadrent des vues spectaculaires sur la skyline de la ville.À lintérieur, chaque détail a été soigneusement pensé pour créer une atmosphère accueillante et sophistiquée. Les matériaux haut de gamme, tels que le marbre et le bois, sont harmonieusement intégrés pour créer une esthétique contemporaine. La cuisine, équipée dappareils de pointe, est un paradis pour les amateurs de gastronomie, tandis que les chambres spacieuses offrent des retraites paisibles avec des vues inspirantes.',
    description2: 'Lextérieur de la résidence ne déçoit pas, avec des espaces de détente soigneusement aménagés, notamment une terrasse ensoleillée et un jardin paysager. Une piscine à débordement ajoute une touche de luxe, offrant une oasis rafraîchissante en plein cœur de la vie citadine. Cette résidence incarne lélégance contemporaine et le raffinement dans un cadre urbain dynamique.'  
  };
  imageUrls: string[] = [
    '/assets/resi3.jpg',
    '/assets/resi1.jpg',
    '/assets/resi2.jpeg',
  ];
  ngOnInit(): void {
  }
  currentIndex = 0;

  showImage(index: number) {
    this.currentIndex = index;
  }

  navigateLeft() {
    this.currentIndex = (this.currentIndex - 1 + this.imageUrls.length) % this.imageUrls.length;
  }

  navigateRight() {
    this.currentIndex = (this.currentIndex + 1) % this.imageUrls.length;
  }
}
